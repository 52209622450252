import React from "react"
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import sert1 from '../images/sertifikatai/1.jpg'
import sert2 from '../images/sertifikatai/2.jpg'
import agne1 from '../images/sertifikatai/agne-ileviciene-2.jpg'
import agne2 from '../images/sertifikatai/agne-ileviciene-3.jpg'
import { Gallery, Item } from 'react-photoswipe-gallery'

const ImageGallery = () => (
    <Gallery>
        <div className="row gallery-row"  data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="1000">
            <div className="col-md-3 col-6">
                <Item
                    original={agne1}
                    thumbnail={agne1}
                    width="1024"
                    height="701"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={agne1} />
                    )}
                </Item>
            </div>
            <div className="col-md-3 col-6">
                <Item
                    original={agne2}
                    thumbnail={agne2}
                    width="1024"
                    height="711"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={agne2} />
                    )}
                </Item>
            </div>

            <div className="col-md-3 col-6">
                <Item
                    original={sert1}
                    thumbnail={sert1}
                    width="1024"
                    height="701"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={sert1} />
                    )}
                </Item>
            </div>
            <div className="col-md-3 col-6">
                <Item
                    original={sert2}
                    thumbnail={sert2}
                    width="1024"
                    height="711"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={sert2} />
                    )}
                </Item>
            </div>

        </div>


    </Gallery>
);

export default ImageGallery;