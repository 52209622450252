import React from "react";
import Slider from "react-slick";
/* eslint-disable */
class ReviewsSlider extends React.Component {
  render() {
    var settings = {
        // className: 'center',
        // centerMode: true,
        infinite: true,
        autoplay: true,
        arrows: true,
        centerPadding: '60px',
        slidesToShow: 3,
        dots: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              autoplay: false,
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      }
    return (
      <div className="reviews-slider">

        <Slider {...settings}>
        <div>
            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsvetlana.jonikiene.7%2Fposts%2F5916027811748315&width=500&show_text=true&appId=163030900754681&height=158" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div>
            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fane.ta.790%2Fposts%2F3468411313208236&width=500&show_text=true&appId=115230505202654&height=187" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmulercikaitekuodiene%2Fposts%2F10215124932988643&width=500&show_text=true&appId=163030900754681&height=186" frameBorder="0"></iframe>
          </div>
          <div>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FIrinkaBad%2Fposts%2F10216705552244535&width=500&show_text=true&appId=163030900754681&height=168" frameBorder="0"></iframe>
          </div>

          <div>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fraimondas.korsakas%2Fposts%2F2328506757160432&width=500&show_text=true&appId=163030900754681&height=168" frameBorder="0"></iframe>
          </div>

          <div>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fgediminas.lescinskis%2Fposts%2F2331956810150663&width=500&show_text=true&appId=163030900754681&height=168" frameBorder="0"></iframe>
          </div>

          <div>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Flarry.murphy.18%2Fposts%2F2452864218064176&width=500&show_text=true&appId=163030900754681&height=186" frameBorder="0"></iframe>
          </div>

          <div>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkristina.jurina.1%2Fposts%2F2416211258420048&width=500&show_text=true&appId=163030900754681&height=186" frameBorder="0"></iframe>
          </div>

        </Slider>
      </div>
    );
  }
}

export default ReviewsSlider