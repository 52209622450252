import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Images from '../components/images'
import agne from '../images/agne-ileviciene.jpg'
import agneround from '../images/agne-ileviciene-round.png'
import spain from '../images/spain.png'
import patirtis from '../images/patirtis.svg'
import derybos from '../images/derybos.svg'
import laikas from '../images/laikas.svg'
import demesys from '../images/demesys.svg'
import ImageGallery from '../components/Gallery'
import ReviewsSlider from "../components/ReviewsSlider"
import phone from '../images/phone.svg'
import email from '../images/email.svg'
// import useSound from 'use-sound';
// import boopSfx from '../sounds/pop-down.mp3';

const IndexPage = () => {
  // const [play, { stop }] = useSound(boopSfx, { volume: 0.1 }); 
  return (
    <Layout>
      <SEO title="Sertifikuota nekilnojamojo turto brokerė." />
      <section className="top-section">
        <div className="container">
          <div className="row align-items-center mobile-reversed">

            <div className='col-md-6'
            >
              <h1 data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500">Agnė <span>Ilevičienė</span></h1>
                <div className="text"  data-sal="slide-up"
                data-sal-delay="250"
                data-sal-easing="ease"
                data-sal-duration="500">
                <p><b>Kiekvienam klientui skiriu ypatingą dėmesį!</b></p>
                 <p>Esu Agnė Ilevičienė, sertifikuota nekilnojamojo turto brokerė, turinti daugiau nei 14 metų darbo patirtį, Lietuvos nekilnojamojo turto agentūrų asociacijos narė.  </p>
                  </div>
               
              <div className="buttons" data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="500">
                <a href="#apie"><button className="btn btn-primary">Apie mane</button></a>
                <a href="#paslaugos"><button className="btn btn-primary">Paslaugos</button></a>
                <Link to="/kontaktai"><button className="btn btn-secondary">Kontaktai</button></Link>

              </div>

            </div>

            <div className='col-md-6 main-image'
              data-sal="fade"
              data-sal-delay="100"
              data-sal-easing="ease"
              data-sal-duration="1000">
              <img className="profile-img desktop-img" src={agne} />
              <img className="profile-img mobile-img" src={agneround} />
            </div>

          </div>

          <hr />

          <div className="row apie-mane" id="apie"
          >
            <div className="col">
              <h3 className="small-title" data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500">Apie mane</h3>
                <ImageGallery />
              {/* <p data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="1000">Brokerio darbą pasirinkau, nes visuomet norėjau dirbti nemonotonišką darbą, turėti galimybę keliauti ir nebūti įpareigotai visą darbo dieną praleisti ofise, taip pat labai žavėjausi derybų menu. Tai paskatino mane įgyti NT formavimo ir kadastrinių matavimų specialybę. Toliau savo žinias NT srityje gilinau VDU žemėtvarkos magistro studijose, nes supratau, kad NT yra būtent mano sritis ir čia galiu pilnai save realizuoti.</p> */}
              <p data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500">Dirbti su nekilnojamuoju turtu pradėjau dar 2008 metais, sunkiuoju krizės laikotarpiu. Žengimas į NT rinką krizės metu leido man išsiugdyti stiprius profesinius įgūdžius, kurie padėjo parduoti NT esant itin prastam nekilnojamojo turto perkamumui šalyje. Šie įgyti įgūdžiai užtikrino mano sėkmę įsitvirtinant NT brokerių rinkoje ir sudarant daugybę pelningų sandorių.</p>
              <p data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500">Per ilgametę darbo patirtį yra tekę dirbti su įvairiausiais projektais: sklypų kvartalų formavimu ir pardavimu, naujos statybos butų, konfiskuoto nekilnojamojo turto bei, žinoma, su įvairaus fizinių asmenų nekilnojamojo turto objektų pirkimu, pardavimu ir nuoma. </p>
              <p data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500">Nemažai brokerių savo profesionalumą pateikia per gebėjimą kuo greičiau sudaryti sandorį - per ilgametę darbo su NT patirtį savo sąskaitoje taip pat turiu daugybę sėkmingų greito NT pardavimo ir pirkimo istorijų. Ne mažiau nei greitai atliktas sandoris, man svarbu išsiaiškinti visus Jūsų lūkesčius ir juos pateisinti. Juk kartais gero pirkėjo ar svajonių būsto verta palaukti, tiesa? </p>
              <p data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500">Mano klientai įsitikino, kad į kiekvieną parduodamą/perkamą objektą žvelgiu kaip į savo asmeninį turtą - skiriu daug dėmesio objekto analizei ir pastebiu tas NT ypatybes, kurios gali suteikti pridėtinę vertę Jūsų būstui. Būtent įsigilinimas į Jūsų parduodamo ar perkamo objekto ypatumus ir savybes leidžia tinkamai nustatyti Jūsų NT objekto vertę bei išsiderėti geriausią kainą. </p>
            </div>
          </div>

          <hr />

          <div className="row paslaugos" id="paslaugos"
          >
            <div className="col">
              <h3 className="small-title"
                data-sal="slide-up"
                data-sal-delay="50"
                data-sal-easing="ease"
                data-sal-duration="500">Paslaugos</h3>

                <div className="row">

           

              <div className="paslauga col-md-6 col-xs-12"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                <h4 className="mini-title">Konsultacijos</h4>
                <p>Konsultuoju investavimo į nekilnojamąjį turtą, geriausių ir palankiausių NT pardavimo galimybių, parduodamo objekto kainos nustatymo, dokumentų paruošimo sandoriui klausimais. Į mane galite kreiptis kylant įvairiems teisiniams ir mokestiniams klausimams. Taip pat rengiu konsultacijas kvartalų formavimo klausimais. Užsiregistruoti konsultacijai galite žemiau nurodytais kontaktais arba užpildę užklausos formą. </p>
              </div>

              <div className="paslauga col-md-6 col-xs-12"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                <h4 className="mini-title">NT Pirkimas</h4>
                <p>Radote patinkantį nekilnojamąjį turtą arba priešingai - nerandate nieko, kas atitiktų Jūsų lūkesčius? Kreipkitės į mane ir padėsiu Jums surasti bei įsigyti savo svajonių būstą, įvertinsiu sandorį, NT objekto kainą ir atstovausiu Jus derybų proceso metu. </p>
              </div>

              <div className="paslauga col-md-6 col-xs-12"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                <h4 className="mini-title">NT Pardavimas</h4>
                <p>Pasirūpinsiu nekilnojamojo turto pardavimo procesu nuo pat pradžių: skelbimų talpinimas, potencialių pirkėjų atranka, objekto aprodymas, derybos ir reikalingų dokumentų tvarkymas - visas NT pardavimo procesas gali būti sunkus ir varginantis neturint jokios patirties, todėl sutaupysiu Jums daug laiko ir energijos bei užtikrinsiu, kad Jūsų nekilnojamasis turtas būtų parduotas už geriausią rinkos kainą. </p>
              </div>

              <div className="paslauga col-md-6 col-xs-12"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                <h4 className="mini-title">NT Nuoma</h4>
                <p>Pasirūpinsiu nekilnojamojo turto pardavimo procesu nuo pat pradžių: skelbimų talpinimas, potencialių pirkėjų atranka, objekto aprodymas, derybos ir reikalingų dokumentų tvarkymas - visas NT pardavimo procesas gali būti sunkus ir varginantis neturint jokios patirties, todėl sutaupysiu Jums daug laiko ir energijos bei užtikrinsiu, kad Jūsų nekilnojamasis turtas būtų parduotas už geriausią rinkos kainą. </p>
              </div>

              <div className="paslauga col-md-6 col-xs-12"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                <h4 className="mini-title">NT Rinkos kainos nustatymas</h4>
                <p>Objektyviai įvertinsiu Jūsų nekilnojamojo turto rinkos kainą, kad iš sandorio gautumėte didžiausią finansinę naudą.</p>
              </div>

              <div className="paslauga col-md-6 col-xs-12"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="500"
              >

                <h4 className="mini-title">Gyvenantiems užsienyje</h4>
                <p>Tarpininkavimas perkant, įsigyjant arba ketinant išsinuomoti nekilnojamąjį turtą Lietuvoje arba Ispanijoje.</p>
              </div>

{/*  */}     </div>

            </div>
          </div>

          {/* <hr /> */}

          <div className="row"
            data-sal="fade"
            data-sal-delay="50"
            data-sal-easing="ease"
            data-sal-duration="300"
          >
            <div className="col">
              <div className="spain-box">
                <div className="left">
                <img src={spain}/>
                <h3>Svajojate apie nekilnojamąjį turtą saulėtoje Ispanijoje? </h3>
                <p><b>Galiu Jums padėti ieškant nekilnojamo turto ir atstovaujant Jus derybų metu ispanų kalba!</b></p>
                <Link to="/kontaktai"><button className="btn btn-primary">Susisiekite</button></Link>
                </div>
                <div className="right">
                  
                </div>
              </div>
              
            </div>
          </div>


          {/* <hr /> */}

          <div className="row verta-rinktis"
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="300"
          >
            <div className="col">

              <h3 className="small-title">Kodėl verta rinktis mane?</h3>

              <div className="row kodel">
                <div className="col-md-6 col-xs-12">
                  <div className="top">
                    <img src={patirtis}/>
                    <h4 className="mini-title">Patirtis</h4>
                  </div>
                 
                  <p>Turiu daugiau nei 14 metų patirtį dirbant su gyvenamosios ir komercinės paskirties nekilnojamuoju turtu.</p>
                </div>
                <div className="col-md-6 col-xs-12">
                <div className="top">
                  <img src={demesys}/>
                    <h4 className="mini-title">Dėmesys</h4>
                  </div>
                <p>Kiekvienam klientui ir sandoriui skiriu maksimalų dėmesį. Tai leidžia iš sandorio gauti kuo didesnę naudą Jums.</p>
                </div>
                <div className="col-md-6 col-xs-12">
                <div className="top">
                    <img src={laikas}/>
                    <h4 className="mini-title">Laikas</h4>
                  </div>
                  <p>Sutaupysiu Jūsų brangų laiką - padėsiu nustatyti kainą, pasirūpinsiu vizualiniu NT objekto pateikimu, atrinksiu motyvuotus pirkėjus, paruošiu sandoriui reikalingus dokumentus.</p>
                </div>
                <div className="col-md-6 col-xs-12">
                <div className="top">
                    <img src={derybos}/>
                    <h4 className="mini-title">Derybos</h4>
                  </div>
                <p>Atstovausiu Jūsų interesus derybų su potencialiais pirkėjais/pardavėjais metu, todėl Jums nereikės išgyventi derybose jaučiamo spaudimo ir įtampos.</p>
                </div>
              </div>
            </div>
          </div>


          <hr />



          <div className="row"
            data-sal="slide-up"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="300"
          >
            <div className="col">
              <h3 className="small-title">Rekomendacijos</h3>
                <ReviewsSlider/>
              {/* <p>reikia rekomendaciju</p> */}
              {/* <p>Galiu Jums padėti ieškant nekilnojamo turto ir atstovaujant Jus derybų metu ispanų kalba! Susisiekite. </p> */}
            </div>
          </div>

          <hr />

          <div className="row"
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="300"
          >
            <div className="col">
              <h3 className="small-title">Kontaktai</h3>
                <div className="row">
                <div className="col-md-6 col-xs-12">
            <p>Darbo dienomis galite susisiekti Jums patogiausiu būdu. Po darbo valandų ar nedarbo dienomis kreipkitės el. paštu arba užpildydami kontaktų formą. </p>
            <p className="adresas"><b>K. Donelaičio g. 40-2, <br />
              Kaunas 44241, Lietuva</b>
            </p>
            <p className="contact-btn"><a href="tel:+37064233366"><img src={phone} /> +370 642 33366</a></p>
            <p className="contact-btn"><a href="mailto:agne.ileviciene@savasbustas.lt"><img src={email} /> agne.ileviciene@savasbustas.lt</a></p>
            <Link to="/kontaktai"><button className="btn btn-secondary">Kontaktų forma</button></Link>

           

          </div>
          <div className="col-md-6 col-xs-12">
          <iframe className="fb-frame" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FNTeksperteAgne&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1097047183649897" width="500" height="500" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
                </div>
            </div>
          </div>


        </div>
      </section>

    </Layout>
  );
};

export default IndexPage;
